import "./Header.css";

function Header() {
    return (
        <header className="header">
            <ul>
                <li>
                    <a
                        className="hover-underline-animation"
                        href="https://cryptonite.live"
                        target="_blank"
                        rel="noreferrer"
                    >
                        About us
                    </a>
                </li>
                {/* <li><a className="hover-underline-animation" href="https://ctf.cryptonite.team/teams" target="_blank" rel="noreferrer">Teams</a></li> */}
                {/* <li><a className="hover-underline-animation" href="https://ctf.cryptonite.team/scoreboard" target="_blank" rel="noreferrer">Scoreboard</a></li> */}
                {/* <li><a className="hover-underline-animation" href="https://ctf.cryptonite.team/notifications" target="_blank" rel="noreferrer">Notifications</a></li> */}
                {/* <li><a className="hover-underline-animation" href="https://ctf.cryptonite.team/challenges" target="_blank" rel="noreferrer">Challenges</a></li> */}
                {/* <li><a className="hover-underline-animation" href="https://ctf.cryptonite.team/rules" target="_blank" rel="noreferrer">Rules</a></li> */}
                {/* <li><a className="hover-underline-animation" href="https://ctf.cryptonite.team/faq" target="_blank" rel="noreferrer">FAQ</a></li> */}
                {/* <li><a className="hover-underline-animation" href="https://ctf.cryptonite.team/register" target="_blank" rel="noreferrer">Register</a></li> */}
                {/* <li><a className="hover-underline-animation" href="https://ctf.cryptonite.team/login" target="_blank" rel="noreferrer">Login</a></li> */}
            </ul>
        </header>
    );
}

export default Header;
